import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    fontFamily:
      '"Montserrat", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: "#31326F",
      light: "#5F5B9E",
      dark: "#000D43",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#BDBDBD",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 280.05 /* TODO: Change to 0 when TestimonialsCardGrid is redone */,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
